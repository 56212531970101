<p *ngIf="tags?.length"
   class="mb-4">
  <span><u>{{'component.voting.tags.category' | translate:{amount: tags.length} }}</u>:&nbsp;{{tags.join(', ')}}</span>
</p>

<div [innerHTML]="sanitizeHTML(questionText)"
     class="mb-0 overflow-hidden mathjax-container"></div>

<ng-container *ngIf="answers">
  <hr/>

  <div *ngFor="let answer of answers; let i = index"
       class="mb-0 overflow-hidden mathjax-container d-flex flex-nowrap">
    <b>{{getNormalizedAnswerIndex(i)}}</b>:&nbsp;<div [innerHTML]="sanitizeHTML(answer)"></div>
  </div>
</ng-container>
