<div class="modal-header">
  <h5 class="modal-title">{{'component.quiz-save.title' | translate}}</h5>
  <button type="button"
          class="close"
          aria-label="Close"
          (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label class="d-block">
        <span>{{'component.quiz-save.access-allowed-for' | translate}}</span>
        <select (change)="quizVisibility = quizVisibilitySelect.value"
                #quizVisibilitySelect
                name="quizVisibility"
                class="custom-select">
          <option selected
                  disabled
                  [value]="null"
                  hidden>{{'global.please-select' | translate}}
          </option>
          <option *ngFor="let option of getQuizVisibility()"
                  [value]="option"
                  [selected]="quizVisibility === option">
            {{option | translate}}
          </option>
        </select>
      </label>
    </div>
  </div>
  <div class="form-row"
       *ngIf="isPublicVisibility()">
    <div class="form-group col-12">
      <label class="d-block">
        <span>{{'component.quiz-save.description' | translate}}</span>
        <textarea class="form-control"
                  name="description"
                  [placeholder]="'component.quiz-save.description' | translate"
                  [(ngModel)]="description"></textarea>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12">
      <label class="d-block">
        <span>{{'component.quiz-save.expiry' | translate}}</span>
        <input type="date"
               [disabled]="noExpiry"
               class="form-control"
               name="expiry"
               [(ngModel)]="expiry"/>
      </label>
    </div>

    <div class="col-12">
      <label class="mb-0 d-flex align-items-center cursor-pointer">
        <span class="checkbox-wrapper">
          <input type="checkbox"
                 [(ngModel)]="noExpiry"/>
          <span class="checkmark bg-white border rounded checked-dark border-dark"></span>
        </span>
        <span class="text-truncate">{{'component.quiz-save.no-expiry' | translate}}</span>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-light"
          [disabled]="isSubmitting"
          (click)="save()">
    <span *ngIf="!isSubmitting">{{'component.quiz-save.save' | translate}}</span>
    <span *ngIf="isSubmitting">{{'component.quiz-save.is-submitting' | translate}}</span>
  </button>
</div>
