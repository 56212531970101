export enum QuizTheme {
  //  ArsnovaDotClickContrast = 'arsnova-dot-click-contrast', //
  Material                = 'Material', //
  Blackbeauty             = 'blackbeauty', //
  //  DecentBlue              = 'decent-blue', //
  //  Elegant                 = 'elegant', //
  //  GreyBlueLime            = 'GreyBlue-Lime', //
  //  MaterialHope            = 'Material-hope', //
  //  MaterialBlue            = 'Material-blue', //
  //  SpiritualPurple         = 'spiritual-purple', //
  WestermannBlue          = 'westermann-blue', //
}
