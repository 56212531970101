<div (click)="triggerBodyVisibility()"
     [class.cursor-pointer]="elem?.questionText.length"
     class="card-header p-2 border-bottom-0 row no-gutters">

  <p class="col-10 col-sm-auto">{{elem?.translationReferrer() | translate}}</p>

  <div class="ml-auto ml-sm-0 d-flex justify-content-end">
    <fa-icon *ngIf="!elem?.isValid()"
             [icon]="'exclamation-triangle'"
             [class.mr-1]="elem?.requiredForToken"
             [ngbTooltip]="'component.quiz_summary.question_failed' | translate"
             class="text-danger ml-sm-2"></fa-icon>

    <fa-icon *ngIf="environment.enableBonusToken && elem?.requiredForToken"
             [icon]="'key'"
             [class.ml-1]="!elem?.isValid()"
             class="text-warning ml-sm-2"
             [ngbTooltip]="'component.quiz_summary.required-for-token.label' | translate"></fa-icon>
  </div>

  <div class="col-12 col-sm-auto ml-sm-auto d-flex">
    <p *ngIf="isChoiceQuestion()"
          class="mr-sm-1">
      {{'component.quiz_summary.answer_amount' | translate:{amount: elem?.answerOptionList?.length} }}
    </p>

    <fa-icon *ngIf="elem?.questionText.length"
             [icon]="isBodyHidden ? 'angle-down' : 'angle-up'"
             class="d-flex ml-auto justify-content-end align-items-center"
             role="button"></fa-icon>
  </div>

  <small *ngIf="elem?.tags?.length"
         class="col-12 font-italic">
    {{elem.tags.join(', ')}}
  </small>
</div>

<p *ngIf="!isBodyHidden && elem?.questionText?.length"
   [innerHtml]="renderedQuestionText"
   class="card-body mh-20vh text-wrap p-2 overflow-hidden">
</p>

<div class="card-footer p-2">

  <div class="d-flex overflow-auto pb-1">
    <button (click)="moveAllUp.next()"
            *ngIf="canMoveAllUp"
            [ngbTooltip]="'component.quiz_manager.question-card.to-top' | translate"
            class="btn btn-light btn-sm mr-2 flex-shrink-0">
      <span class="d-none d-md-inline mr-2">{{'component.quiz_manager.question-card.to-top' | translate}}</span>
      <fa-icon [icon]="'angle-double-up'"
               role="button"></fa-icon>
    </button>

    <button (click)="moveUp.next()"
            *ngIf="canMoveUp"
            [ngbTooltip]="'component.quiz_manager.question-card.up' | translate"
            class="btn btn-light btn-sm mr-2 flex-shrink-0">
      <span class="d-none d-md-inline mr-2">{{'component.quiz_manager.question-card.up' | translate}}</span>
      <fa-icon [icon]="'arrow-up'"
               role="button"></fa-icon>
    </button>

    <button (click)="moveAllDown.next()"
            *ngIf="canMoveAllDown"
            [ngbTooltip]="'component.quiz_manager.question-card.to-bottom' | translate"
            class="btn btn-light btn-sm mr-2 flex-shrink-0">
      <span class="d-none d-md-inline mr-2">{{'component.quiz_manager.question-card.to-bottom' | translate}}</span>
      <fa-icon [icon]="'angle-double-down'"
               role="button"></fa-icon>
    </button>

    <button (click)="moveDown.next()"
            *ngIf="canMoveDown"
            [ngbTooltip]="'component.quiz_manager.question-card.down' | translate"
            class="btn btn-light btn-sm mr-2 flex-shrink-0">
      <span class="d-none d-md-inline mr-2">{{'component.quiz_manager.question-card.down' | translate}}</span>
      <fa-icon [icon]="'arrow-down'"
               role="button"></fa-icon>
    </button>

    <div *ngIf="environment.enableQuizPool"
         [ngbTooltip]="getTooltipForUpload() | translate"
         class="flex-shrink-0">
      <button (click)="updloadToQuizPool()"
              [disabled]="!elem?.isValid() || !elem?.tags?.length"
              class="btn btn-light btn-sm mr-2">
        <span class="d-none d-lg-inline mr-2">{{'component.quiz_manager.question-card.quiz-pool-upload' | translate}}</span>
        <fa-icon [class.text-danger]="!elem?.isValid() || !elem?.tags?.length"
                 [icon]="['fas', (isUploading ? 'spinner' : 'cloud-upload-alt')]"
                 [spin]="isUploading"
                 role="button"></fa-icon>
      </button>
    </div>

    <button (click)="edit.next()"
            [ngbTooltip]="'component.quiz_manager.question-card.edit' | translate"
            [routerLink]="['/quiz', 'manager', index,  'overview']"
            [class.ml-auto]="!environment.enableQuizPool"
            class="btn btn-light btn-sm mr-2 flex-shrink-0">
      <span class="d-none d-lg-inline mr-2">{{'component.quiz_manager.question-card.edit' | translate}}</span>
      <fa-icon [icon]="'edit'"
               role="button"></fa-icon>
    </button>

    <button (click)="delete.next()"
            [ngbTooltip]="'component.quiz_manager.question-card.delete' | translate"
            class="btn btn-light btn-sm flex-shrink-0">
      <span class="d-none d-lg-inline mr-2">{{'component.quiz_manager.question-card.delete' | translate}}</span>
      <fa-icon [icon]="'trash'"
               role="button"></fa-icon>
    </button>
  </div>

</div>
