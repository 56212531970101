<button (click)="openQuestionTypeModal()"
        class="btn btn-light btn-shadow my-4 mt-md-1 mx-auto mx-md-0 d-flex">
  <span>{{'component.quiz_manager.select-available-question' | translate}}</span>
  <fa-icon [icon]="'plus'" class="ml-2">  
  </fa-icon>
</button>

<h2 *ngIf="quizService.quiz?.questionList.length as questionListLength"
    (click)="questionListLength > 1 ? toggleQuestionBody() : null"
    [class.cursor-pointer]="questionListLength > 1"
    class="d-flex align-items-center">
  <span>{{'component.quiz_manager.added_questions_title' | translate:{amount: questionListLength} }}</span>

  <span *ngIf="questionListLength > 1"
        class="ml-auto">
    <fa-icon *ngIf="!hasAllQuestionBodiesHidden()"
             [icon]="'angle-double-up'"
             class="mx-2"
             role="button"></fa-icon>
    <fa-icon *ngIf="hasAllQuestionBodiesHidden()"
             [icon]="'angle-double-down'"
             class="mx-2"
             role="button"></fa-icon>
  </span>
</h2>

<h4 *ngIf="!quizService.quiz?.questionList.length">{{'component.quiz_manager.no_added_questions' | translate}}</h4>

<ul class="list-unstyled full-xs">
  <ng-container *ngIf="isLoaded">
    <app-question-card (bodyVisibility)="toggleQuestionBody(elem)"
                       (delete)="deleteQuestion(i)"
                       (edit)="trackEditQuestion()"
                       (moveDown)="moveQuestionDown(i)"
                       (moveUp)="moveQuestionUp(i)"
                       (moveAllDown)="moveQuestionAllDown(i)"
                       (moveAllUp)="moveQuestionAllUp(i)"
                       *ngFor="let elem of quizService.quiz?.questionList; let i = index;"
                       [canMoveDown]="i !== quizService.quiz?.questionList.length - 1"
                       [canMoveAllDown]="i < quizService.quiz?.questionList.length - 2"
                       [canMoveUp]="i > 0"
                       [canMoveAllUp]="i > 1"
                       [elem]="elem"
                       [index]="i"
                       [isBodyHidden]="hasQuestionBodyHidden(elem)"
                       [revalidate]="revalidate"
                       class="card mb-3"
                       role="listitem"></app-question-card>
  </ng-container>
</ul>
