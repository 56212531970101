<ng-template #modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'hotkey.modal-title' | translate}}</h5>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <div *ngFor="let hotkey of hotkeys; odd as isOdd; even as isEven"
           class="d-flex align-items-center p-2 list-group-item"
           [class.bg-light]="isOdd"
           [class.bg-secondary]="isEven">

        <div class="d-inline-block">{{hotkey.description}}</div>

        <button class="d-flex ml-auto btn btn-sm btn-primary"
                [disabled]="hotkey.combo.includes('esc')"
                (click)="hotkey.callback(null, hotkey.combo)">
          <div *ngFor="let key of formatCombo(hotkey.combo); let i = index"
               [class.ml-3]="i">
            <span *ngIf="IS_MAC && key === 'ALT'">⌥</span>
            <fa-icon *ngIf="key === 'LEFT'"
                     [icon]="'long-arrow-alt-left'"></fa-icon>
            <fa-icon *ngIf="key === 'RIGHT'"
                     [icon]="'long-arrow-alt-right'"></fa-icon>
            <ng-container *ngIf="(!IS_MAC || !['ALT'].includes(key)) && !['LEFT', 'RIGHT'].includes(key)">{{key}}</ng-container>
          </div>
        </button>
      </div>
    </ul>
  </div>
</ng-template>
