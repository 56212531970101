<div class="modal-header">
  <h3 class="m-0">{{'component.quiz_manager.available_questions_title' | translate}}</h3>

  <button (click)="dismiss()"
          aria-label="Close"
          class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <input [(ngModel)]="filterQuizType"
         [placeholder]="'component.quiz_manager.available-questions-filter' | translate"
         class="form-control mb-3"
         type="text"/>

  <div class="row">
    <div *ngFor="let elem of selectableQuestionTypes | genericFilter:{$translateKeys: true, translationName: filterQuizType}"
         class="col-12 col-sm-6 mb-2">
      <div class="card h-100">
        <div class="card-header">
          <span class="card-title">{{elem.translationName | translate}}</span>
        </div>
        <div class="card-body d-flex flex-column">
          <p class="mb-3">{{elem.descriptionType | translate}}</p>
          <button (click)="close(elem.id)"
                  class="btn mt-auto btn-light">
            <span>{{'view.question_type.select-type' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
