<div class="modal-header">
  <h5 class="modal-title">{{'component.server-unavailable-modal.title' | translate}}</h5>
</div>
<div class="modal-body">
  <p>{{'component.server-unavailable-modal.connection-dropped' | translate}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-light"
          (click)="reloadPage()">{{'component.server-unavailable-modal.reload-page' | translate}}
  </button>
</div>