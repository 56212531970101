<nav class="navbar navbar-header flex-nowrap align-items-center p-0"
     id="header">
  <div class="arsnova-logo d-flex align-items-center cursor-pointer">
    <a [routerLink]="interactiveLogo ? ['/'] : null" class="text-decoration-none" [class.d-md-none]="logoSize !== 'xs'">
      <svg-icon src="/assets/images/logo.svg"></svg-icon>
    </a>
    <a *ngIf="logoSize !== 'xs'"
       [routerLink]="interactiveLogo ? ['/'] : null" class="d-none d-md-block text-decoration-none">
      <svg-icon src="/assets/images/logo.xl.svg"></svg-icon>
    </a>
  </div>
  <div *ngIf="showHeader"
       class="cursor-default d-flex align-items-center">
    <div class="center-block">
      <h4 *ngIf="!headerLabelService.subHeader && (headerLabelService.headerLabel !== 'default' || headerLabelService.hasHeaderLabelParams())"
           [innerHTML]="headerLabelService.headerLabel | translate:headerLabelService.headerLabelParams"
           class="text-center header-label mb-0"></h4>
      <h4 *ngIf="headerLabelService.subHeader"
          class="text-center header-label mb-0">
        <p [innerHTML]="headerLabelService.headerLabel | translate:headerLabelService.headerLabelParams"></p>
        <small>{{headerLabelService.subHeader}}</small>
      </h4>
    </div>
  </div>
  <div class="text-right d-flex align-items-center cursor-pointer p-0">
    <svg #connectionIndicator
         #connectionIndicatorPopover="ngbPopover"
         (click)="openConnectionQualityModal(connectionQualityModal)"
         [ngbPopover]="'region.header.connection_status.disconnected' | translate"
         [style.margin-top.px]="connectionService.lowSpeed ? -20 : connectionService.mediumSpeed ? -10 : 0"
         height="50px"
         id="connection-indicator"
         triggers="manual"
         version="1.1"
         viewBox="0 0 128 128"
         width="50px"
         xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg">
          <g>
              <g>
                  <path d="M73.143,79.904c-2.585-1.189-5.692-2.014-9.139-2.01c-3.446-0.004-6.553,0.82-9.137,2.01            c-2.609,1.201-4.702,2.736-6.365,4.236l8.505,8.506c0.894-0.744,1.919-1.426,3.031-1.912c1.199-0.52,2.473-0.836,3.965-0.84      c1.564,0.004,2.891,0.352,4.139,0.918c1.047,0.475,2.007,1.137,2.855,1.842l8.515-8.514c-0.008-0.008-0.016-0.016-0.023-0.021       C77.832,82.629,75.736,81.096,73.143,79.904z"
                        id="firstRow"></path>
                <path d="M80.057,62.309c-4.579-2.104-9.994-3.537-16.047-3.533c-6.053-0.004-11.466,1.429-16.045,3.531      c-5.233,2.405-9.402,5.632-12.597,8.701l8.486,8.486c0.45-0.426,0.917-0.854,1.411-1.275c2.287-1.965,5.03-3.842,8.151-5.205     c3.128-1.365,6.615-2.234,10.594-2.238c4.171,0.004,7.806,0.961,11.048,2.441c3.594,1.641,6.674,3.975,9.104,6.273l8.486-8.486      c-0.391-0.375-0.793-0.752-1.213-1.131C88.422,67.166,84.645,64.413,80.057,62.309z"
                      id="secondRow"></path>
                <path *ngIf="!connectionService.lowSpeed && connectionService.serverAvailable"
                      d="M104.078,55.731c-4.45-3.999-10.01-8.043-16.711-11.116c-6.693-3.072-14.553-5.151-23.369-5.147     c-8.815-0.003-16.674,2.074-23.366,5.145c-7.741,3.555-13.948,8.383-18.692,12.967l8.487,8.487                                  c0.602-0.578,1.22-1.156,1.874-1.734c3.733-3.307,8.295-6.542,13.581-8.925c5.294-2.384,11.29-3.937,18.116-3.94                    c6.934,0.003,13.014,1.606,18.369,4.056c6.101,2.788,11.225,6.728,15.211,10.55l8.484-8.484    C105.424,56.973,104.77,56.354,104.078,55.731z"
                      id="thirdRow"></path>
                <path *ngIf="!connectionService.lowSpeed && !connectionService.mediumSpeed && connectionService.serverAvailable"
                      d="M97.259,27.395c-9.55-4.381-20.715-7.333-33.265-7.33c-12.55-0.003-23.712,2.946-33.26,7.325        c-9.341,4.287-17.149,9.901-23.464,15.519l8.502,8.502c5.602-4.907,12.416-9.71,20.346-13.286                                   c8.119-3.658,17.374-6.056,27.876-6.06c10.668,0.003,20.053,2.479,28.266,6.239c7.766,3.553,14.46,8.291,19.973,13.117              l8.498-8.498C114.414,37.307,106.601,31.678,97.259,27.395z"
                      id="fourthRow"></path>
              </g>
            <circle cx="64.008"
                    cy="102.109"
                    id="circleRow"
                    r="5.825"></circle>
          </g>
    </svg>
  </div>
</nav>

<ng-template #connectionQualityModal
             let-c="close"
             let-d="dismiss"
             role="dialog">
  <div class="modal-header">
    <h5 [innerHTML]="'region.header.connection_status.title' | translate"
        class="modal-title"
        id="exampleModalLabel"></h5>
    <button (click)="d()"
            aria-label="Close"
            class="close cursor-pointer"
            data-dismiss="modal"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="p-0 list-unstyled">
      <li>
        <fa-icon [class.text-danger]="!connectionService.serverAvailable"
                 [class.text-success]="connectionService.serverAvailable"
                 [icon]="connectionService.serverAvailable ? 'check' : 'times'"></fa-icon>
        <p class="ml-2 d-inline">
          <span>Server: </span>
          <span *ngIf="connectionService.serverAvailable">{{'region.header.connection_status.server_status.available' | translate}}</span>
          <span *ngIf="!connectionService.serverAvailable">{{'region.header.connection_status.server_status.not_available' | translate}}</span>
        </p>
      </li>
      <li>
        <fa-icon [class.text-danger]="!connectionService.websocketAvailable"
                 [class.text-success]="connectionService.websocketAvailable"
                 [icon]="connectionService.websocketAvailable ? 'check' : 'times'"></fa-icon>
        <p class="ml-2 d-inline">
          <span>Websocket: </span>
          <span *ngIf="connectionService.websocketAvailable">{{'region.header.connection_status.websocket_status.connected' | translate}}</span>
          <span *ngIf="!connectionService.websocketAvailable">{{'region.header.connection_status.websocket_status.not_connected' | translate}}</span>
        </p>
      </li>
      <li>
        <fa-icon [class.text-danger]="connectionService.rtt > 300 || !connectionService.serverAvailable"
                 [class.text-success]="connectionService.rtt <= 300 && connectionService.serverAvailable"
                 [icon]="connectionService.rtt <= 300 && connectionService.serverAvailable ? 'check' : 'times'"></fa-icon>
        <p class="ml-2 d-inline">
          <span>Round-Trip-Time: </span>
          <span *ngIf="connectionService.serverAvailable">{{connectionService.rtt}} ms</span>
          <span *ngIf="!connectionService.serverAvailable">{{'region.header.connection_status.server_status.not_available' | translate}}</span>
        </p>
      </li>
    </ul>

    <p class="text-muted">{{'region.header.connection_status.estimated-storage-quota.label' | translate}}:</p>
    <p class="mb-3">
      <span *ngIf="!storage.quota">{{'region.header.connection_status.estimated-storage-quota.not_available' | translate}}</span>
      <span *ngIf="storage.quota">{{'region.header.connection_status.estimated-storage-quota.currently-used' | translate:{QUOTA: i18nService.formatNumber(round(storage.quota / 1024 / 1024)), USAGE: i18nService.formatNumber(round(storage.usage / 1024 / 1024))} }}</span>
    </p>

    <p class="text-muted">{{'region.header.connection_status.current-version.label' | translate }}</p>
    <p class="mb-3">{{'region.header.connection_status.current-version.version' | translate:{version: version} }}</p>

    <div>
      <button (click)="checkForUpdates()"
              [disabled]="isCheckingForUpdates || !connectionService.serverAvailable"
              class="btn btn-primary">
        <span *ngIf="!isCheckingForUpdates">{{'region.header.connection_status.check-for-updates' | translate}}</span>
        <span *ngIf="isCheckingForUpdates">
          <span class="mr-2">{{'region.header.connection_status.checking-for-updates' | translate}}</span>
          <fa-icon [icon]="'spinner'"
                   [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="d()"
            [innerHTML]="'global.close_window' | translate"
            class="btn btn-light"
            data-dismiss="modal"
            type="button"></button>
  </div>
</ng-template>
