<div class="mt-3 mt-sm-0 mt-md-3 px-md-0"
     id="wrapper">

  <div class="row no-gutters">

    <div class="col-6 col-md-12 mb-2 d-flex d-md-block">
      <div [attr.aria-label]="'component.quiz_summary.description.header' | translate"
           [attr.data-intro]="'component.quiz_summary.description.header' | translate"
           class="element-wrapper d-flex flex-column bg-white text-dark px-2 py-2 flex-grow-1 mw-100">
        <h5 class="text-center">{{'component.quiz_summary.quiz_name' | translate}}</h5>
        <div class="d-flex flex-wrap">
          <p class="d-flex align-self-center align-items-end text-bold text-center ml-sm-auto mr-md-auto"
             role="listitem">
            <span>{{quizService.quiz?.name}}</span>
          </p>

          <button (click)="renameQuiz()"
                  [attr.aria-label]="'component.quiz_summary.quiz_rename' | translate"
                  [title]="'component.quiz_summary.quiz_rename' | translate"
                  class="btn btn-sm btn-outline-primary text-truncate mx-auto mr-sm-0 mr-md-auto">
            <fa-icon [icon]="'edit'"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="col-6 col-md-12 mb-2 d-flex d-md-block">
      <div [attr.aria-label]="'component.quiz_summary.description.question_group_validation' | translate"
           [attr.data-intro]="'component.quiz_summary.description.question_group_validation' | translate"
           class="element-wrapper d-flex flex-column bg-white text-dark px-2 py-2 flex-grow-1 mw-100">
        <h5 class="text-center">{{'component.quiz_summary.validation_question_group' | translate}}</h5>
        <p class="d-flex align-self-center align-items-end text-bold text-center"
           role="listitem">
          <span *ngIf="quizService.quiz?.isValid()"
                class="text-success">
            {{'component.quiz_summary.question_group_successful' | translate}}
          </span>
          <span *ngIf="!quizService.quiz?.isValid()"
                class="text-danger">
            {{'component.quiz_summary.question_group_failed' | translate}}
          </span>
        </p>
      </div>
    </div>

    <div *ngIf="quizService.quiz?.sessionConfig.nicks.selectedNicks.length === 0 && isShowingMore"
         class="col-6 col-md-12 mb-2 d-flex d-md-block">
      <div class="element-wrapper bg-white text-dark px-2 py-2 flex-grow-1 mw-100">
        <h5 class="text-center text-hyphenate">{{'component.quiz_summary.isRestrictingRudeNicks' | translate}}</h5>
        <p class="text-center text-break"
           role="listitem">
          <span *ngIf="quizService.quiz?.sessionConfig.nicks.blockIllegalNicks">
            {{'global.yes' | translate}}
          </span>
          <span *ngIf="!quizService.quiz?.sessionConfig.nicks.blockIllegalNicks">
            {{'global.no' | translate}}
          </span>
        </p>
      </div>
    </div>

    <div *ngIf="isShowingMore"
         [class.col-12]="quizService.quiz?.sessionConfig.nicks.selectedNicks.length > 0"
         class="col-6 col-md-12 mb-2 d-flex d-md-block">
      <div class="element-wrapper bg-white text-dark px-2 py-2 flex-grow-1 mw-100">
        <h5 class="text-center">{{'component.quiz_summary.selected_nicks' | translate}}</h5>

        <p *ngIf="!quizService.quiz?.sessionConfig.nicks.selectedNicks.length"
           class="text-center text-break">
          {{'component.quiz_summary.no_selected_nicks' | translate}}
        </p>
        <p *ngIf="quizService.quiz?.sessionConfig.nicks.selectedNicks.length"
           class="text-break">
          {{'component.quiz_summary.selected_nicks' | translate}}&#58;&nbsp;{{quizService.quiz?.sessionConfig.nicks.selectedNicks.length}}
        </p>
      </div>
    </div>

    <button (click)="switchShowMoreOrLess()"
            class="btn btn-block btn-light mt-2 mt-sm-0 d-md-none">
      <span *ngIf="isShowingMore">{{'component.quiz_manager.show_less' | translate}}</span>
      <span *ngIf="!isShowingMore">{{'component.quiz_manager.show_more' | translate}}</span>
    </button>
  </div>
</div>
