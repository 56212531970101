<div class="modal-header">
  <h5 class="modal-title">Add key</h5>
  <button type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input #addKeyInput
         (keyup)="key = addKeyInput.value"
         class="form-control mb-2"
         [value]="key"
         type="text"
         placeholder="Enter the Key here, eg. component.name.group.title"/>

  <div *ngFor="let langKey of getKeys(languageLoaderService.languageTranslation)">
    <p>{{languageLoaderService.languageTranslation[langKey]}}</p>
    <textarea class="key-textarea form-control mb-2"
              (keyup)="updateKey($event, langKey)"></textarea>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-warning cursor-pointer"
          data-dismiss="modal"
          (click)="dismiss()">Abort
  </button>
  <button type="button"
          class="btn btn-success cursor-pointer"
          data-dismiss="modal"
          [disabled]="!hasValues()"
          (click)="save()">Save
  </button>
</div>
