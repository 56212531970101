<div class="row center-vertical">

  <div class="col-12">

    <div class="row"
         role="list">

      <div *ngFor="let elem of availableLanguage"
           class="col-xs-12 col-md-6 col-sm-6 col-lg-6 mb-2 available_translations"
           role="listitem">

        <button [id]="'translation_' + elem.tag"
                [disabled]="i18nService.currentLanguage.toString().toUpperCase() === elem.tag"
                class="btn btn-secondary btn-block cursor-pointer"
                (click)="changeLanguage(elem.tag)">
          <span>{{elem.text}}</span>

        </button>

      </div>

    </div>

  </div>

</div>
