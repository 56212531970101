<div class="modal-header">
  <h4 class="modal-title">Add new User</h4>
  <button type="button"
          class="close"
          aria-label="Close"
          (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>Username</span>
        <input type="text"
               class="form-control"
               name="name"
               placeholder="Username"
               [(ngModel)]="name"/>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>Password</span>
        <input type="password"
               class="form-control"
               name="password"
               placeholder="Password"
               [(ngModel)]="password"/>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>Token</span>
        <input [(ngModel)]="tokenHash"
               class="form-control"
               name="token"
               placeholder="Token"
               type="text"/>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>Gitlab Token</span>
        <input type="text"
               class="form-control"
               placeholder="Api Access Token (optional)"
               name="gitlabToken"
               [(ngModel)]="gitlabToken"/>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>Private Key</span>
        <input type="text"
               class="form-control"
               placeholder="Private Key Token"
               name="privateKey"
               [(ngModel)]="privateKey"/>
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12 col-sm">
      <label class="w-100">
        <span>User Authorizations</span>
        <select [(ngModel)]="userAuthorizations"
                name="userAuthorizations"
                class="form-control"
                multiple="multiple">
          <option *ngFor="let option of getUserRoles()"
                  [value]="option"
                  [selected]="userAuthorizations.includes(option)">
            {{option}}
          </option>
        </select>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-light"
          [disabled]="isSubmitting"
          (click)="save()">
    <span *ngIf="!isSubmitting">Save</span>
    <span *ngIf="isSubmitting">Submitting...</span>
  </button>
</div>
